import React, { Component } from 'react';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';
import API from '../../../services/api';

// Components

import CronogramaForm from './form';
import CronogramaList from './list';

// Styles

import { SubContainer, SubContent } from '../../../css/styles/wrapper';

class CronogramaContainer extends Component {
  state = {
    isLoading: true,
    cronogramas: null,
    cronograma: null,
  };

  refCronograma = React.createRef();

  async componentDidMount() {
    this.mount();
  }

  mount() {
    this.getCronogramas();
  }

  async getCronogramas() {
    this.setState({
      isLoading: true,
    });

    const { turno } = this.props;

    API.get(`cronogramas/${turno.uuid}`).then(res => {
      const cronogramas = res.data;

      this.setState({
        cronogramas,

        isLoading: false,
      });
    });
  }

  handleCronogramaDelete = async uuid => {
    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Cronograma?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      API.delete(`cronogramas/${uuid}`).then(() => {
        this.getCronogramas();

        this.setState({
          cronograma: null,
        });
      });
    }
  };

  handleCronogramaCreation = async values => {
    const { turno } = this.props;

    try {
      await API.post(
        'cronogramas',

        {
          nu_seq_cronograma: values.nu_seq_cronograma,

          hr_inicio: values.hr_inicio,

          qt_hr_trabalho: values.qt_hr_trabalho,

          hr_inicio_intervalo: values.hr_inicio_intervalo
            ? values.hr_inicio_intervalo
            : null,

          qt_hr_intervalo: values.qt_hr_intervalo,

          cd_cronograma: values.cd_cronograma,

          turnoUuid: turno.uuid,

          fl_meia_folga: values.fl_meia_folga || false,

          fl_virada: values.fl_virada || false,

          qt_limite_trabalhado: values.qt_limite_trabalhado,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getCronogramas();
    } catch (err) {
      alert(err);
    }
  };

  handleCronogramaUpdate = async values => {
    const { turno } = this.props;
    const { cronograma } = this.state;

    try {
      await API.put(
        `cronogramas/${cronograma.uuid}`,

        {
          nu_seq_cronograma: values.nu_seq_cronograma,

          hr_inicio: values.hr_inicio,

          qt_hr_trabalho: values.qt_hr_trabalho,

          hr_inicio_intervalo: values.hr_inicio_intervalo
            ? values.hr_inicio_intervalo
            : null,

          qt_hr_intervalo: values.qt_hr_intervalo,

          cd_cronograma: values.cd_cronograma,

          turnoUuid: turno.uuid,

          fl_meia_folga: values.fl_meia_folga || false,

          fl_virada: values.fl_virada || false,

          qt_limite_trabalhado: values.qt_limite_trabalhado,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getCronogramas();

      this.handleNew();
    } catch (err) {
      alert(err);
    }
  };

  handleEdit = object => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      cronograma: object,
    }));

    this.scrollToMyRef(this.refCronograma);
  };

  handleNew = () => {
    // eslint-disable-next-line no-unused-vars
    this.setState(prevState => ({
      cronograma: null,
    }));
  };

  scrollToMyRef = myRef => {
    window.scrollTo(0, myRef.current.offsetTop);
  };

  render() {
    const { isLoading, cronogramas, cronograma, turno } = this.state;

    return (
      <SubContainer>
        <header>
          <h1>CRONOGRAMAS</h1>
        </header>

        <SubContent>
          <CronogramaList
            isLoading={isLoading}
            onCronogramaDelete={this.handleCronogramaDelete}
            cronogramas={cronogramas}
            onCronogramaEdit={this.handleEdit}
          />
          <CronogramaForm
            onCronogramaCreation={this.handleCronogramaCreation}
            onCronogramaUpdate={this.handleCronogramaUpdate}
            onNew={this.handleNew}
            turno={turno}
            cronograma={cronograma}
          />

          <div ref={this.refCronograma} />
        </SubContent>
      </SubContainer>
    );
  }
}

CronogramaContainer.propTypes = {
  turno: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
};
export default CronogramaContainer;
