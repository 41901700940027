import React, { Component } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import swal from '@sweetalert/with-react';

import API from '../../services/api';
import Loading from '../components/Loading';
import createAuditoria from '../../services/auditoria';

// Components

import FuncionarioForm from './form';
import FuncionarioFilterForm from './filterForm';
import FuncionarioList from './list';
import AlocacaoCargoContainer from './AlocacaoCargo';
import AlocacaoTurnoContainer from './AlocacaoTurno';
import FrequenciaContainer from './Frequencia';

// Styles

import {
  Wrapper,
  Container,
  Content,
  Pagination,
} from '../../css/styles/wrapper';

class FuncionarioContainer extends Component {
  state = {
    modalAddJust: false,
    isLoading: true,
    funcionarios: null,
    funcionariosFull: null,
    funcionario: null,
    turnos: null,
    departamentos: null,
    isLoadingFuncionario: false,
    isLoadingFrequency: false,
    ShowFrequency: false,
    frequencia: null,
    showFormToPrintFrequency: true,
    situacao: 'A',
    nm_funcionario: null,
    funcionarioUuid: null,
    cargoContratos: null,
    printState: false,
    proximo: null,
    anterior: null,
    anteriorFull: null,
    proximoFull: null,
    mesInicial:
      // eslint-disable-next-line radix
      parseInt(
        moment()
          .subtract(1, 'months')
          .format('MM')
      ) - 1,
    anoInicial: moment()
      .subtract(1, 'months')
      .format('YYYY'),
    closed: false,
  };

  async componentDidMount() {
    createAuditoria('FUNCIONARIO', localStorage.getItem('USER_UUID'));
    const { match } = this.props;

    this.mount(match.params.uuid);
  }

  async mount(uuid) {
    const { ShowFrequency } = this.state;

    this.handleNewFrequency();
    await this.getFuncionarios();

    if (uuid) {
      await this.getFuncionario(uuid);
      await this.handlePagination(uuid);
    }

    if (ShowFrequency) {
      await this.handleSearchFrequency();
    }

    await this.getCargos();

    await this.getFuncionariosFull();

    await this.getDepartamentos();
  }

  componentWillReceiveProps(nextProps) {
    const { uuid } = nextProps.match.params;

    this.mount(uuid);
  }

  async getDepartamentos() {
    API.get(`departamentos`).then(res => {
      const result = res.data;

      this.setState({
        departamentos: result,
      });
    });
  }

  async handleClosedFrequency() {
    const { funcionario, mesInicial, anoInicial } = this.state;

    const res = await API.get(
      `fichaDeFrequencias/${funcionario.uuid}/${parseInt(mesInicial, 10) +
        1}/${anoInicial}`
    );

    if (res.data) {
      const corpo = JSON.parse(res.data.corpo);
      corpo.totalBancoDeHoraGeral = res.data.banco_de_horas;
      corpo.totalDescontadoGeral = res.data.descontado;
      corpo.totalExtra50Geral = res.data.extra_50;
      corpo.totalExtra100Geral = res.data.extra_100;
      corpo.totalNoturnoGeral = res.data.noturno;
      corpo.totalNoturnoMultiplicado = res.data.noturno_calculado;
      corpo.totalTrabalhadoGeral = res.data.trabalhado;
      corpo.totalTime = res.data.total_time;
      corpo.uuid = res.data.uuid;

      return corpo;
    }

    return null;
  }

  getCargos = async () => {};

  getFuncionarios = async values => {
    await this.setState(prevState => ({
      isLoading: true,
      situacao: values ? values.situacao : prevState.situacao,
      nm_funcionario: values ? values.nm_funcionario : prevState.nm_funcionario,
      funcionarioUuid: values
        ? values.funcionarioUuid
        : prevState.funcionarioUuid,
    }));

    const { situacao, nm_funcionario, funcionarioUuid } = this.state;

    await API.get(
      `funcionarios/${situacao || 'A'}/${nm_funcionario ||
        null}/${funcionarioUuid || null}`
    ).then(res => {
      const result = res.data;

      this.setState({
        funcionarios: result,

        isLoading: false,
      });
    });
  };

  getFuncionariosFull = async () => {
    await API.get(`funcionarios/A/null/null`).then(res => {
      const result = res.data;

      this.setState({
        funcionariosFull: result,
      });
    });
  };

  getFuncionario = async uuid => {
    this.setState({
      isLoadingFuncionario: true,

      modalAddJust: true,
    });

    await API.get(`funcionarios/${uuid}`).then(res => {
      const funcionario = res.data[0];

      this.handlePagination(funcionario.uuid);

      this.setState({
        funcionario,

        isLoadingFuncionario: false,
      });
    });
  };

  handleFuncionarioDelete = async () => {
    const { funcionario } = this.state;

    const confirmDelete = await swal({
      dangerMode: true,

      text: 'Confirma a exclusão do Funcionario?',

      buttons: {
        cancel: 'Não',

        confirm: 'Sim',
      },
    });

    if (confirmDelete) {
      await API.put(
        `funcionarios/${funcionario.uuid}`,

        { dt_exclusao: moment() },

        { headers: { 'Content-Type': 'application/json' } }
      ).catch(error => {
        throw error;
      });

      this.getFuncionarios();

      this.setState({
        funcionario: null,

        modalAddJust: false,
      });
    }
  };

  handleFuncionarioCreation = async values => {
    try {
      const rawResponse = await API.post(
        'funcionarios',

        {
          nm_funcionario: values.nm_funcionario,

          cd_funcionario: values.cd_funcionario,

          cd_externo_funcionario: values.cd_externo_funcionario,

          nu_cpf: values.nu_cpf,

          dt_admissao: `${values.dt_admissao}T00:00:00`,

          funcionarioUuid: values.funcionarioUuid
            ? values.funcionarioUuid
            : null,

          departamentoUuid: values.departamentoUuid,

          end_funcionario: values.end_funcionario,

          cep_funcionario: values.cep_funcionario,

          uf_funcionario: values.uf_funcionario,

          situacao: values.situacao,

          dt_exclusao: values.dt_exclusao ? values.dt_exclusao : null,

          nu_pis_pasep: values.nu_pis_pasep,

          fl_responsavel_ponto: values.fl_responsavel_ponto,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        const err = error.response ? error.response.data : error;
        throw err;
      });

      const content = await rawResponse;

      this.getFuncionario(content.data.uuid);
    } catch (err) {
      alert(err);
    }
  };

  handleFuncionarioUpdate = async values => {
    const { funcionario } = this.state;

    try {
      await API.put(
        `funcionarios/${funcionario.uuid}`,

        {
          nm_funcionario: values.nm_funcionario,

          cd_funcionario: values.cd_funcionario,

          cd_externo_funcionario: values.cd_externo_funcionario,

          nu_cpf: values.nu_cpf,

          dt_admissao: values.dt_admissao,

          end_funcionario: values.end_funcionario,

          cep_funcionario: values.cep_funcionario,

          uf_funcionario: values.uf_funcionario,

          departamentoUuid: values.departamentoUuid,

          situacao: values.situacao,

          dt_exclusao: values.dt_exclusao ? values.dt_exclusao : null,

          nu_pis_pasep: values.nu_pis_pasep,

          funcionarioUuid: values.funcionarioUuid
            ? values.funcionarioUuid
            : null,

          fl_responsavel_ponto: values.fl_responsavel_ponto,
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      ).catch(error => {
        throw error;
      });

      this.getFuncionarios();

      this.setState({ modalAddJust: false, funcionario: null });
    } catch (err) {
      alert(err);
    }
  };

  async getCargoContratos() {
    API.get(`cargoContratos`).then(res => {
      const result = res.data;

      this.setState({
        cargoContratos: result,
      });
    });
  }

  handleFuncionarioFrequency = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    this.setState({ ShowFrequency: true, frequencia: null });

    this.handleSearchFrequency();
  };

  handleCloseFrequency = () => {
    this.setState({
      ShowFrequency: false,
      mesInicial:
        // eslint-disable-next-line radix
        parseInt(
          moment()
            .subtract(1, 'months')
            .format('MM')
        ) - 1,
      anoInicial: moment()
        .subtract(1, 'months')
        .format('YYYY'),
    });
  };

  handleNewFrequency = () => {
    this.setState({
      mesInicial:
        // eslint-disable-next-line radix
        parseInt(
          moment()
            .subtract(1, 'months')
            .format('MM')
        ) - 1,
      anoInicial: moment()
        .subtract(1, 'months')
        .format('YYYY'),
      frequencia: null,
    });
  };

  handleSearchFrequency = async (mes, ano, begin, end) => {
    await this.setState(prevState => ({
      mesInicial: mes || prevState.mesInicial,
      anoInicial: ano || prevState.anoInicial,
    }));

    const frequenciaFechada = await this.handleClosedFrequency();
    if (frequenciaFechada && !begin && !end) {
      this.setState({
        frequencia: frequenciaFechada,

        isLoadingFrequency: false,

        printState: true,

        closed: true,
      });
    } else {
      const { funcionario } = this.state;
      let dataInicial = moment();
      let dataFinal = moment();

      if (!begin && !end) {
        const { mesInicial, anoInicial } = this.state;

        const diaInicialPeriodo = 16;
        const diaFinalPeriodo = 15;

        dataInicial = moment().set(`year`, anoInicial);
        dataInicial.set(`month`, mesInicial);

        // const metadeMesInicial = Math.floor(dataInicial.daysInMonth() / 2);

        dataInicial.set(`date`, diaInicialPeriodo);
        dataFinal = moment(dataInicial).add(1, 'months');

        // const metadeMesFinal = Math.floor(dataFinal.daysInMonth() / 2);

        dataFinal.set('date', diaFinalPeriodo);
      } else {
        dataInicial = moment(begin);
        dataFinal = moment(end);
      }

      this.setState({
        isLoadingFrequency: true,
      });

      API.get(
        `calendarios/funcionario/${funcionario.uuid}/${dataInicial
          .startOf('day')
          .format()}/${dataFinal.startOf('day').format()}`
      ).then(res => {
        const result = res.data;

        let printState = !(begin || end);

        if (result) {
          result.dados.map(calendario => {
            const pontosQtd = calendario.calendarios
              ? calendario.calendarios.length
              : 0;

            let justificativaAbono = false;

            if (
              calendario &&
              calendario.cronograma.data_justificada &&
              calendario.cronograma.data_justificada.justificativa.fl_abono
            ) {
              justificativaAbono = true;
            }

            if (pontosQtd % 2 !== 0 && pontosQtd <= 4 && !justificativaAbono) {
              printState = false;
            }
          });
        }

        this.setState({
          frequencia: result,

          isLoadingFrequency: false,

          printState,

          closed: false,
        });
      });
    }
  };

  handlePagination = uuid => {
    const { funcionarios, funcionariosFull } = this.state;

    let anterior = null;
    let proximo = null;
    let anteriorFull = null;
    let proximoFull = null;

    if (funcionarios && funcionarios[0]) {
      const index = funcionarios.findIndex(
        funcionario => funcionario.uuid === uuid
      );

      anterior = funcionarios[index - 1];
      proximo = funcionarios[index + 1];

      if (
        funcionariosFull &&
        funcionariosFull[0] &&
        funcionariosFull.length !== funcionarios.length
      ) {
        const indexFull = funcionariosFull.findIndex(
          funcionario => funcionario.uuid === uuid
        );

        anteriorFull = funcionariosFull[indexFull - 1];
        proximoFull = funcionariosFull[indexFull + 1];
      }

      this.setState({
        anterior,
        proximo,
        anteriorFull,
        proximoFull,
      });
    }
  };

  render() {
    const {
      modalAddJust,
      isLoadingFuncionario,
      funcionario,
      funcionarios,
      turnos,
      frequencia,
      departamentos,
      ShowFrequency,
      showFormToPrintFrequency,
      isLoadingFrequency,
      funcionariosFull,
      situacao,
      nm_funcionario,
      funcionarioUuid,
      cargoContratos,
      isLoading,
      mesInicial,
      anoInicial,
      printState,
      anterior,
      proximo,
      anteriorFull,
      proximoFull,
      closed,
    } = this.state;

    return (
      <Wrapper>
        {ShowFrequency !== true ? (
          <Container>
            <header>
              <h1>FUNCIONARIOS</h1>

              {modalAddJust && funcionario && turnos && turnos[0] && (
                <button type="button" onClick={this.handleFuncionarioFrequency}>
                  FICHA DE FREQUÊNCIA
                </button>
              )}

              <Link
                to="/funcionarios"
                onClick={() => {
                  this.setState(prevState => ({
                    modalAddJust: !prevState.modalAddJust,

                    funcionario: null,
                  }));
                }}
              >
                {!modalAddJust ? 'ADICIONAR' : 'LISTA'}
              </Link>
            </header>

            <Content>
              {modalAddJust ? (
                <>
                  {!isLoadingFuncionario && !isLoading ? (
                    <>
                      {funcionario && (
                        <Pagination>
                          {anteriorFull && (
                            <Link
                              to={`/funcionarios/${anteriorFull &&
                                anteriorFull.uuid}`}
                            >
                              <MdKeyboardArrowLeft color="#bf1523" size={35} />
                            </Link>
                          )}
                          {anterior && (
                            <Link
                              to={`/funcionarios/${anterior && anterior.uuid}`}
                            >
                              <MdKeyboardArrowLeft color="#333" size={35} />
                            </Link>
                          )}
                          <h1>{funcionario.nm_funcionario}</h1>
                          {proximo && (
                            <Link
                              to={`/funcionarios/${proximo && proximo.uuid}`}
                            >
                              <MdKeyboardArrowRight color="#333" size={35} />
                            </Link>
                          )}
                          {proximoFull && (
                            <Link
                              to={`/funcionarios/${proximoFull &&
                                proximoFull.uuid}`}
                            >
                              <MdKeyboardArrowRight color="#bf1523" size={35} />
                            </Link>
                          )}
                        </Pagination>
                      )}

                      <FuncionarioForm
                        onFuncionarioCreation={this.handleFuncionarioCreation}
                        onFuncionarioUpdate={this.handleFuncionarioUpdate}
                        isLoadingFuncionario={isLoadingFuncionario}
                        onFuncionarioDelete={this.handleFuncionarioDelete}
                        funcionario={funcionario}
                        departamentos={departamentos}
                        onShowFrequency={this.handleFuncionarioFrequency}
                        funcionarios={funcionariosFull}
                      />

                      {funcionario && (
                        <>
                          <AlocacaoCargoContainer funcionario={funcionario} />
                          <AlocacaoTurnoContainer
                            funcionario={funcionario}
                            onGetTurno={e => this.setState({ turnos: e })}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <Loading />
                  )}
                </>
              ) : (
                <>
                  {!isLoadingFuncionario && !isLoading ? (
                    <>
                      <FuncionarioFilterForm
                        onList={this.getFuncionarios}
                        funcionarios={funcionariosFull}
                        situacao={situacao}
                        nm_funcionario={nm_funcionario}
                        funcionarioUuid={funcionarioUuid}
                        cargoContratos={cargoContratos}
                      />

                      <FuncionarioList
                        isLoading={isLoading}
                        onFuncionarioDelete={this.handleFuncionarioDelete}
                        onFuncionarioGet={this.getFuncionario}
                        funcionarios={funcionarios}
                      />
                    </>
                  ) : (
                    <Loading />
                  )}
                </>
              )}
            </Content>
          </Container>
        ) : (
          <>
            {!isLoadingFuncionario && !isLoading ? (
              <>
                <FrequenciaContainer
                  funcionario={funcionario}
                  frequencia={frequencia}
                  onSearchFrequency={this.handleSearchFrequency}
                  showFormToPrintFrequency={showFormToPrintFrequency}
                  isLoadingFrequency={isLoadingFrequency}
                  onShowFrequencyChange={this.handleCloseFrequency}
                  onList={() => {
                    this.setState(prevState => ({
                      modalAddJust: !prevState.modalAddJust,
                      funcionario: null,
                    }));
                    this.handleCloseFrequency();
                  }}
                  printState={printState}
                  anterior={anterior}
                  proximo={proximo}
                  mesInicial={mesInicial}
                  anoInicial={anoInicial}
                  closed={closed}
                />
              </>
            ) : (
              <Loading />
            )}
          </>
        )}
      </Wrapper>
    );
  }
}

FuncionarioContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }).isRequired,
};

export default FuncionarioContainer;