import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { FormStyled } from '../../../css/styles/form';
import imgloading from '../../../img/load.gif';

const Loadingbtn = styled.div`
  width: 44px;

  height: 44px;

  background: url(${imgloading}) no-repeat center;

  display: none;
`;

const handleValidate = ({
  nu_seq_cronograma,
  hr_inicio,
  qt_hr_trabalho,
  qt_hr_intervalo,
  cd_cronograma,
}) => {
  const errors = {};

  if (!nu_seq_cronograma) errors.nu_seq_cronograma = 'Obrigatório!';

  if (!hr_inicio) errors.hr_inicio = 'Obrigatório!';

  if (!qt_hr_trabalho) errors.qt_hr_trabalho = 'Obrigatório!';

  if (!qt_hr_intervalo) errors.qt_hr_intervalo = 'Obrigatório!';

  if (!cd_cronograma) errors.cd_cronograma = 'Obrigatório!';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting, resetForm }) => {
  if (!props.cronograma) {
    await props.onCronogramaCreation({ ...values });
  } else {
    await props.onCronogramaUpdate({ ...values });
  }

  setSubmitting(false);

  resetForm();
};

const CronogramaForm = props => {
  const { cronograma, onNew } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        nu_seq_cronograma: cronograma ? cronograma.nu_seq_cronograma : '',

        hr_inicio: cronograma ? cronograma.hr_inicio : '',

        qt_hr_trabalho: cronograma ? cronograma.qt_hr_trabalho : '',

        hr_inicio_intervalo: cronograma ? cronograma.hr_inicio_intervalo : '',

        qt_hr_intervalo: cronograma ? cronograma.qt_hr_intervalo : '',

        cd_cronograma: cronograma ? cronograma.cd_cronograma : 'TRABALHO',

        fl_meia_folga:
          cronograma && cronograma.fl_meia_folga ? 'true' : 'false',

        fl_virada: cronograma && cronograma.fl_virada ? 'true' : 'false',

        qt_limite_trabalhado: cronograma ? cronograma.qt_limite_trabalhado : '',
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleReset,
        isSubmitting,
        dirty,
      }) => (
        <FormStyled>
          <h1>{cronograma ? 'ALTERAR CRONOGRAMA' : 'NOVO CRONOGRAMA'}</h1>
          <div className="formData">
            <strong>TIPO</strong>
            <select
              id="cd_cronograma"
              name="cd_cronograma"
              value={values.cd_cronograma}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="TRABALHO">TRABALHO</option>

              <option value="FOLGA">FOLGA</option>
            </select>
            {errors.cd_cronograma && touched.cd_cronograma && (
              <span>{errors.cd_cronograma}</span>
            )}
          </div>
          <div className="formData">
            <strong>NUMERO SEQUENCIAL</strong>
            <input
              placeholder="Digite o numero sequencial..."
              id="nu_seq_cronograma"
              name="nu_seq_cronograma"
              value={values.nu_seq_cronograma}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.nu_seq_cronograma && touched.nu_seq_cronograma && (
              <span>{errors.nu_seq_cronograma}</span>
            )}
          </div>
          <div className="formData">
            <strong>HORA DE INÍCIO</strong>
            <input
              id="hr_inicio"
              name="hr_inicio"
              value={values.hr_inicio}
              onChange={handleChange}
              onBlur={handleBlur}
              type="time"
            />
            {errors.hr_inicio && touched.hr_inicio && (
              <span>{errors.hr_inicio}</span>
            )}
          </div>
          <div className="formData">
            <strong>QUANTIDADE DE HORAS TRABALHADAS</strong>
            <input
              id="qt_hr_trabalho"
              name="qt_hr_trabalho"
              value={values.qt_hr_trabalho}
              onChange={handleChange}
              onBlur={handleBlur}
              type="time"
            />
            {errors.qt_hr_trabalho && touched.qt_hr_trabalho && (
              <span>{errors.qt_hr_trabalho}</span>
            )}
          </div>
          <div className="formData">
            <strong>HORA INÍCIO INTERVALO</strong>
            <input
              id="hr_inicio_intervalo"
              name="hr_inicio_intervalo"
              value={values.hr_inicio_intervalo}
              onChange={handleChange}
              onBlur={handleBlur}
              type="time"
            />
            {errors.hr_inicio_intervalo && touched.hr_inicio_intervalo && (
              <span>{errors.hr_inicio_intervalo}</span>
            )}
          </div>
          <div className="formData">
            <strong>QUANTIDADE DE HORAS INTERVALOS</strong>
            <input
              id="qt_hr_intervalo"
              name="qt_hr_intervalo"
              value={values.qt_hr_intervalo}
              onChange={handleChange}
              onBlur={handleBlur}
              type="time"
            />
            {errors.qt_hr_intervalo && touched.qt_hr_intervalo && (
              <span>{errors.qt_hr_intervalo}</span>
            )}
          </div>
          <div className="formData">
            <strong>LIMITE DE EXPEDIENTE</strong>
            <input
              id="qt_limite_trabalhado"
              name="qt_limite_trabalhado"
              value={values.qt_limite_trabalhado}
              onChange={handleChange}
              onBlur={handleBlur}
              type="time"
            />
            {errors.hr_inicio_intervalo && touched.hr_inicio_intervalo && (
              <span>{errors.hr_inicio_intervalo}</span>
            )}
          </div>
          {values.cd_cronograma === 'FOLGA' && (
            <div className="formData">
              <strong>MEIA FOLGA</strong>
              <RadioGroup
                aria-label="fl_meia_folga"
                name="fl_meia_folga"
                value={values.fl_meia_folga}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Sim"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>
          )}

          <div className="formData">
            <strong>VIRADA</strong>
            <RadioGroup
              aria-label="fl_virada"
              name="fl_virada"
              value={values.fl_virada}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Sim"
                labelPlacement="end"
              />

              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Não"
                labelPlacement="end"
              />
            </RadioGroup>
          </div>

          <button type="submit" disabled={isSubmitting}>
            <Loadingbtn className="loadingbtn" />
            SALVAR
          </button>
          <button
            type="button"
            onClick={handleReset}
            disabled={!dirty || isSubmitting}
          >
            LIMPAR
          </button>

          {cronograma && (
            <button type="button" onClick={onNew}>
              NOVO
            </button>
          )}
        </FormStyled>
      )}
    </Formik>
  );
};

CronogramaForm.propTypes = {
  cronograma: PropTypes.shape({
    nu_seq_cronograma: PropTypes.string,
    hr_inicio: PropTypes.string,
    qt_hr_trabalho: PropTypes.string,
    hr_inicio_intervalo: PropTypes.string,
    qt_hr_intervalo: PropTypes.string,
    cd_cronograma: PropTypes.string,
    fl_meia_folga: PropTypes.bool,
    fl_virada: PropTypes.bool,
  }),
  onNew: PropTypes.func.isRequired,
};

CronogramaForm.defaultProps = {
  cronograma: null,
};

export default CronogramaForm;
